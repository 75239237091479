/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from '@react-three/fiber'

export default function Model00(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/Caixa00.glb");
  useFrame(() => (group.current.rotation.y += 0.0023))
  const acrilicoMaterial = <meshStandardMaterial color={'#1a1c1f'} envMapIntensity={0.5} roughness={0.15} opacity={0.95}/>
  return (
    <group ref={group} {...props} dispose={null} scale={0.3}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Acrilico.geometry}
        rotation={[Math.PI / 2, 0, 0]}
      >{acrilicoMaterial}</mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Overlay.geometry}
        material={materials["Default OBJ.002"]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Adesivo.geometry}
        material={materials["Default OBJ.004"]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Caixa.geometry}
        material={materials["Default OBJ.001"]}
        rotation={[Math.PI / 2, 0, 0]}
      />
    </group>
  );
}

useGLTF.preload("/Caixa00.glb");
