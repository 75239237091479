const BlogContent = [
    {
        images: '01',
        title: 'Chorão Skatista',
        category: '#01',
        link: '/chorao'
    },
    {
        images: '02',
        title: 'EM BREVE',
        category: '#02',
        link: '/'
    },

    {
        images: '02',
        title: 'EM BREVE',
        category: '#03',
        link: '/'
    },
]

export default BlogContent;